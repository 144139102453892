import axios from 'axios';

export default class DataService {

  async searchByNonsig(nonsig) {
    return await axios.get(`/api/search-nonsig/${nonsig}`)
      .catch(error => { throw error });
  }

  async getUserName(uname) {
    return await axios.get(`/api/get-uname/${uname}`)
      .catch(error => { throw error });
  }

  async createUserinGAIMSUrl(uname) {
    return await axios.get(`/api/create-user-in-gaims/${uname}`)
      .catch(error => { throw error });
  }

  // POST requests

  async sendContactUs(data) { return await axios
      .post(`/api/contact-email`, JSON.stringify(data))
      .then(response => {
        return response
      })
      .catch(error => { throw error });
  }

  async createUserInSuccessFactors(data) {
    return await axios
      .post(`/api/create-user-in-success-factors`, JSON.stringify(data))
      .then(response => {
        return response
      })
      .catch(error => { throw error });
  }

  async walmartLogin(data) { return await axios
    .post(`/api/walmart-login`, JSON.stringify(data))
    .then(response => {
      return response
    })
    .catch(error => { throw error });
  }  

}